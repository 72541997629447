import Model from './Model'

export default class ConstructionWorkerModel extends Model {
  id!: number
  firstname!: string
  lastname!: string
  address!: string

  resource () : string {
    return 'constructionWorkers'
  }
}
