import { defineStore } from 'pinia'
import { ref } from 'vue'
import TableSettings from '@/types/tableSettings'
import ConstructionWorkerModel from '@/models/ConstructionWorkerModel'
import { notification } from 'ant-design-vue'
import { WrappedResponse } from 'vue-api-query'
import { authStore } from '@/stores/auth'

export const constructionWorkerStore = defineStore('constructionWorker', () => {
  const loading = ref({ list: false })
  const constructionWorkersList = ref<WrappedResponse<ConstructionWorkerModel[]>|ConstructionWorkerModel[]>({
    data: [],
    meta: {
      per_page: 25,
      total: 0
    }
  })

  function getConstructionWorkers (tableSettings:TableSettings, withInactive = false) : void {
    loading.value.list = true
    let constructionWorkerModel = new ConstructionWorkerModel()

    // Add filters if there are any filters set.
    if (tableSettings.activeFilters) {
      Object.keys(tableSettings.activeFilters).forEach(key => {
        if (tableSettings.activeFilters[key].length) {
          constructionWorkerModel = constructionWorkerModel.whereIn(key, tableSettings.activeFilters[key])
        }
      })
    }

    if (withInactive) {
      constructionWorkerModel = constructionWorkerModel.where('withInactive', 1)
    }

    // Only show own employees if authenticated user does not have the employee role (user is external).
    if (!authStore().isInternal) {
      constructionWorkerModel = constructionWorkerModel.where('company.id', authStore().user.company_id)
    }

    // Add search if available.
    if (tableSettings.search) {
      constructionWorkerModel = constructionWorkerModel.where('search', tableSettings.search)
    }

    // Add orderBy if sort is set.
    if (tableSettings.sort && tableSettings.sort.order && tableSettings.sort.columnKey) {
      constructionWorkerModel = constructionWorkerModel.orderBy(tableSettings.sort.order === 'ascend' ? tableSettings.sort.columnKey : '-' + tableSettings.sort.columnKey)
    }

    if (tableSettings.pagination) {
      constructionWorkerModel = constructionWorkerModel.limit(tableSettings.pagination.pageSize).page(tableSettings.pagination.current)
    }

    constructionWorkerModel.get().then((elementList) => {
      constructionWorkersList.value = elementList
    }).catch(() => {
      notification.error({ message: 'Fout tijdens het ophalen van de bouwplaatsmedewerkers!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 3 })
    }).finally(() => {
      loading.value.list = false
    })
  }

  return { constructionWorkersList, loading, getConstructionWorkers }
})
